<template>
    <b-overlay :show="dataLoaded">
        <b-card>
            <b-alert show class="p-1 text-center" variant="danger">Pri urejanju oglasov mora biti orodje za preprečevanje oglasov (npr. AdBlock, UBlock Origin, Brave Browser) izključen!</b-alert>
            <b-alert class="mt-1 p-1 text-center" show variant="warning" v-if="hasBeenDeleted">Po brisanju je potrebnih nekaj sekund, da je brisanje izvedeno. Če je oglas po brisanju še vedno viden, ponovno naložite stran.</b-alert>
            <b-button class="mt-1" variant="primary" @click="$router.push({name: 'add-advertisement'})">Dodaj oglas</b-button>
            <Table ref="table" class="mt-1" :fields="fields" route="/api/management/v1/es/advertisements" :fromElastic="true">
                <template #cell(main_photo)="row">
                    <b-img v-if="row.item.main_photo !== ''" fluid  :src="'/api/management/v1/image/thumbnail_' + row.item.main_photo" style="width: 100%; max-height: 70px; object-fit: contain;" alt="Image"/>
                </template>
                <template #cell(device_type)="row">
                    {{getTitleFromArray(row.item.device_type, 'device')}}
                </template>
                <template #cell(page_type)="row">
                    {{getTitleFromArray(row.item.page_type, 'page')}}
                </template>
                <template #cell(show_from)="row">
                    {{moment(row.item.show_from).format('DD.MM.YYYY')}}
                </template>
                <template #cell(show_until)="row">
                    {{moment(row.item.show_until).format('DD.MM.YYYY')}}
                </template>
                <template #cell(url)="row">
                    <a :href="row.item.url">{{row.item.url}}</a>
                </template>
                <template #cell(active)="row">
                    <b-badge v-if="row.item.active" variant="light-success">
                        Da
                    </b-badge>
                    <b-badge v-else variant="light-danger">
                        Ne
                    </b-badge>
                </template>
                <template #cell(actions)="row">
                    <div class="d-flex justify-content-center">
                        <b-button class="mr-1" variant="warning" @click="$router.push({name: 'edit-advertisement', params:{ advertisement_id: row.item.id }})"><fa icon="edit"/></b-button>
                        <b-button @click="deleteAd(row.item.id)" variant="danger"><fa icon="trash"/></b-button>
                    </div>
                </template>
            </Table>
        </b-card>
    </b-overlay>
</template>
<script>
    import Table from '@/views/Components/Table'
    import {BAlert, BBadge, BButton, BCard, BImg, BOverlay} from 'bootstrap-vue'
    export default {
        components: {
            Table,
            BAlert,
            BOverlay,
            BCard,
            BButton,
            BImg,
            BBadge
        },
        data() {
            return {
                dataLoaded: false,
                type: '',
                fields: [
                    { key: 'main_photo', label: 'Slika', sortable: true, class: 'text-center' },
                    { key: 'device_type', label: 'Naprave', sortable: true, class: 'text-center' },
                    { key: 'page_type', label: 'Strani', sortable: true, class: 'text-center' },
                    { key: 'show_from', label: 'Začetek prikazovanja', sortable: true, class: 'text-center', type: 'date' },
                    { key: 'show_until', label: 'Konec prikazovanja', sortable: true, class: 'text-center', type: 'date' },
                    { key: 'url', label: 'Url', sortable: true, class: 'text-center' },
                    { key: 'active', label: 'Aktiven', sortable: true, class: 'text-center', type: 'bool' },
                    { key: 'actions', label: '', class: 'text-center' }
                ],
                hasBeenDeleted: false

            }
        },
        methods: {
            deleteAd(id) {
                this.$bvModal.msgBoxConfirm('Prosimo potrdite, da želite izbrisati oglas.', {
                    size: 'sm',
                    okTitle: 'Da',
                    cancelTitle: 'Ne',
                    hideHeaderClose: false,
                    okVariant: 'danger',
                    cancelVariant: 'outline-secondary',
                    centered: true
                }).then(value => {
                    if (value) {
                        const thisIns = this
                        thisIns.dataLoaded = true
                        const loadPromise = this.$http.delete(`/api/management/v1/advertisement/${id}`)
                        loadPromise.then(function() {
                            thisIns.$printSuccess('Oglas je izbrisan')

                            thisIns.hasBeenDeleted = true

                            setTimeout(() => {
                                thisIns.hasBeenDeleted = false
                            }, 10_000)
                            setTimeout(() => {
                                thisIns.$refs.table.search()
                            }, 1000)
                        }).catch(function(error) {
                            thisIns.$printError(error.response.data)
                        }).finally(function() {
                            thisIns.dataLoaded = false
                        })
                    }
                })
            },
            getTitleFromArray(array, type) {
                if (!array) return '/'
                const items = []
                if (type === 'device') {
                    for (const item of array) {
                        if (item === '0') items.push('SPLET')
                        else if (item === '1') items.push('MOBILNI')
                    }
                } else if (type === 'page') {
                    for (const item of array) {
                        if (item === '0') items.push('DOMOV')
                        else if (item === '1') items.push('NOVICE')
                        else if (item === '2') items.push('NEPREMIČNINE')
                        else if (item === '3') items.push('MALI OGLASI')
                        else if (item === '4') items.push('SPLETNA TRGOVINA')
                    }
                }
                return items.map(p => { return p }).join(', ')
            }
        }
    }
</script>
